import React, { useState } from "react";
import { Link } from "gatsby";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import TabletNav from "components/TabletNav";
import { Hamburger } from "./Hamburger";

import Logo from "./Logo";

const Nav = ({
  theme,
  menu,
  logoLink,
  twitter,
  linkedin,
  instagram,
  bespokeHome,
}) => {
  const menuItems = menu && menu.menuItems.nodes;
  const [isOpen, setIsOpen] = useState(false);
  const setTheme = `theme-${theme}` || "theme-group";

  return (
    <>
      <nav
        className={`${
          theme !== "bespoke"
            ? "absolute"
            : bespokeHome
            ? "bg-transparent md:bg-firefly"
            : "bg-firefly"
        } top-0 w-full z-[60]`}
      >
        <div className="lg:container mx-6 lg:mx-auto flex justify-between items-center p-4 pb-8 md:pb-4 lg:p-0 lg:h-[120px]">
          <Link to={logoLink}>
            <Logo theme={theme} />
          </Link>
          <div className="hidden md:flex items-center">
            <div
              className={`md:hidden lg:flex space-x-4 ${
                theme !== "commercial" ? "text-white" : ""
              } ${setTheme}`}
            >
              {menu &&
                menuItems.map((menuItem) => {
                  return (
                    <Link
                      key={menuItem.id}
                      to={menuItem.path}
                      className={`border-b-2 border-b-transparent hover-hover:hover:border-b-2 hover-hover:hover:border-b-skin-color`}
                      partiallyActive={true}
                      activeClassName={`border-b-2 font-bold border-b-skin-color`}
                    >
                      {menuItem.label}
                    </Link>
                  );
                })}
            </div>
            <div
              className={`ml-8 flex space-x-3 items-center ${
                theme !== "commercial" ? "text-white" : ""
              } ${isOpen ? "md:hidden" : ""}`}
            >
              {twitter && (
                <a
                  href={twitter}
                  className={`${
                    theme !== "commercial"
                      ? "hover:text-stormdust"
                      : "hover:text-cinnabar"
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter />
                </a>
              )}
              {linkedin && (
                <a
                  href={linkedin}
                  className={`${
                    theme !== "commercial"
                      ? "hover:text-stormdust"
                      : "hover:text-cinnabar"
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              )}
              {instagram && (
                <a
                  href={instagram}
                  className={`${
                    theme !== "commercial"
                      ? "hover:text-stormdust"
                      : "hover:text-cinnabar"
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              )}
            </div>
            <Hamburger theme={theme} isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
      </nav>
      <TabletNav
        logoLink={logoLink}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        menu={menu}
        theme={theme}
      />
    </>
  );
};

export default Nav;
