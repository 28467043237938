import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Logo = ({ className }) => {
  return (
    <StaticImage
      className={`w-[249px] h-[auto] ${className}`}
      src={`../images/Grayscourt_Group_Logo_SBS_White.svg`}
      alt={`Group logo`}
    />
  );
};

export default Logo;
