import React, { useState } from "react";
import { Link } from "gatsby";
import { BsEnvelope, BsList, BsHouseDoor, BsX } from "react-icons/bs";

import Logo from "images/LogoShape";

const MobileNav = ({ theme, menu, logoLink }) => {
  const menuItems = menu && menu.menuItems.nodes;
  const [isOpen, setIsOpen] = useState(false);
  const setTheme = `theme-${theme}` || "theme-group";
  let bgColor;
  if (theme === "commercial") {
    bgColor = "bg-cinnabar";
  } else {
    bgColor = "bg-stormdust";
  }

  return (
    <nav className={`${setTheme} md:hidden`}>
      {isOpen && (
        <div
          className={`fixed z-50 bottom-0 flex justify-center items-center left-0 w-full h-full p-6 pb-32 ${
            theme === "commercial" ? "bg-everglade" : "bg-firefly"
          } -shadow-md`}
        >
          <Logo
            fill={`white`}
            className={`absolute w-2/3 right-[-5%] md:right-[55%] transform rotate-180 bottom-0 opacity-10`}
          />
          <div
            className={`flex flex-col justify-center items-center text-[24px] uppercase text-center space-y-4 text-white`}
          >
            {menu &&
              menuItems.map((menuItem) => {
                return (
                  <Link
                    key={menuItem.id}
                    to={menuItem.path}
                    partiallyActive={true}
                    activeClassName={`border-b-2 font-bold ${
                      theme === "commercial" ? "border-b-cinnabar" : ""
                    }`}
                  >
                    {menuItem.label}
                  </Link>
                );
              })}
          </div>
        </div>
      )}
      <div className="fixed z-50 bottom-0 left-0 w-full grid grid-cols-3 divide-x-2 bg-white text-stormdust -shadow-md">
        <Link to={`${logoLink}`}>
          <div className="flex flex-col items-center gap-2 p-4">
            <BsHouseDoor className="w-8 h-8" />
            <span className="text-sm">Home</span>
          </div>
        </Link>
        {menu && (
          <button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <div
              className={`flex flex-col items-center gap-2 p-4 ${
                isOpen ? bgColor + " text-white" : ""
              }`}
            >
              {isOpen && <BsX className="w-8 h-8" />}
              {!isOpen && <BsList className="w-8 h-8" />}

              <span className="text-sm">{!isOpen ? "Menu" : "Close"}</span>
            </div>
          </button>
        )}
        <Link to={`${logoLink}/contact`}>
          <div className="flex flex-col items-center gap-2 p-4">
            <BsEnvelope className="w-8 h-8" />
            <span className="text-sm">Contact</span>
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default MobileNav;
