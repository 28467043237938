import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Logo = ({ className }) => {
  return (
    <StaticImage
      className={`w-[249px] h-[auto] ${className}`}
      src={`../../images/Grayscourt_Bespoke-Living_Logo_SBS_RGB_White_Foil.png`}
      alt={`Bespoke logo`}
    />
  );
};

export default Logo;
