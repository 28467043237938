import React from "react";
import { Link } from "gatsby";
import { BsArrowRight } from "react-icons/bs";

const LinkInternal = ({ label, target, theme, className }) => {
  return (
    <Link
      to={target}
      className={`flex items-center text-sm font-bold hover:underline ${theme} ${className}`}
    >
      {label}
      <BsArrowRight className={`ml-2 text-xl`} />
    </Link>
  );
};

export default LinkInternal;
