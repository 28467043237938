import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ConstructionLineLogo = ({ className }) => {
  return (
    <StaticImage
      className={`w-[90px] h-[auto] ${className}`}
      src={`../images/Construction_Line_Gold_Member.png`}
      alt={`Construction Line logo`}
    />
  );
};

export default ConstructionLineLogo;
