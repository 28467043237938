import React from "react";

import { default as CommercialLogo } from "./commercial/Logo";
import { default as BespokeLogo } from "./bespoke/Logo";
import { default as GroupLogo } from "./GroupLogo";

const Logo = ({ theme }) => {
  console.log(theme);
  return theme === "commercial" ? (
    <CommercialLogo className={`w-44 h-10 md:w-64 md:h-14`} />
  ) : theme === "group" ? (
    <GroupLogo />
  ) : (
    <BespokeLogo />
  );
};

export default Logo;
