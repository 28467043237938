import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ChasLogo = ({ className }) => {
  return (
    <StaticImage
      className={`w-[90px] h-[auto] ${className}`}
      src={`../images/CHAS-_Premium_Plus.png`}
      alt={`CHAS logo`}
    />
  );
};

export default ChasLogo;
