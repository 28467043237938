import React from "react";
import { Link } from "gatsby";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import Obfuscate from "react-obfuscate";

import LogoBig from "./LogoBig";
import ChasLogo from "./ChasLogo";
import ConstructionLineLogo from "./ConstructionLineLogo";

const Footer = ({
  theme,
  menu,
  twitter,
  instagram,
  linkedin,
  address,
  phone,
  email,
}) => {
  const copyrightYear = new Date().getFullYear();
  const menuItems = menu && menu.menuItems.nodes;
  const setTheme = `theme-${theme}` || "theme-group";

  return (
    <footer
      className={`${setTheme} text-skin-base bg-skin-fill py-8 sm:px-6 lg:px-0 pb-28 md:pb-8 text-sm tracking-wide`}
    >
      <div className="md:container mx-6 md:mx-auto">
        <div className="flex flex-col md:flex-row flex-wrap justify-between gap-4 sm:gap-8 lg:gap-4">
          <div className="w-32 fill-white mb-2 sm:mb-0">
            {/* Logo */}
            <LogoBig />
          </div>
          <div className="flex flex-col space-y-4 sm:w-full lg:w-auto">
            <address className="not-italic">{address}</address>
            <div className="text-base space-y-3 md:space-y-0">
              <div>
                <span className="text-skin-accent">T:</span>
                <Obfuscate className="ml-2 hover:underline" tel={phone} />
              </div>
              <div>
                <span className="text-skin-accent">E:</span>
                <Obfuscate
                  className="ml-2 hover:underline"
                  email={email}
                  headers={{
                    subject: "Enquiry",
                  }}
                />
              </div>
            </div>
          </div>
          {/* Footer menu */}
          {menuItems && (
            <div className="flex flex-col space-y-3 my-8 md:my-0 md:space-y-1 leading-6 text-base">
              {menuItems.map((menuItem) => {
                return (
                  <Link
                    key={menuItem.id}
                    to={menuItem.path}
                    className="hover:underline"
                  >
                    {menuItem.label}
                  </Link>
                );
              })}
            </div>
          )}
          <div className="flex justify-between md:justify-start">
            {/* Social links */}
            <div className="md:mr-16">
              <div className="text-skin-accent">Follow us</div>
              <div className="mt-2 text-xl md:text-base flex space-x-4 items-center">
                {twitter && (
                  <a
                    href={twitter}
                    className={`${
                      theme === "bespoke"
                        ? "hover:text-stormdust"
                        : "hover:text-cinnabar"
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter />
                  </a>
                )}
                {linkedin && (
                  <a
                    href={linkedin}
                    className={`${
                      theme === "bespoke"
                        ? "hover:text-stormdust"
                        : "hover:text-cinnabar"
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                )}
                {instagram && (
                  <a
                    href={instagram}
                    className={`${
                      theme === "bespoke"
                        ? "hover:text-stormdust"
                        : "hover:text-cinnabar"
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram />
                  </a>
                )}
              </div>
            </div>
            <div className="relative flex flex-col gap-y-2 top-1 mb-4">
              {/* Credits / CHAS Logo */}
              <ChasLogo />
              <ConstructionLineLogo />
            </div>
          </div>
        </div>
        <div className="flex justify-between font-light text-xs mt-4 sm:mt-8 lg:mt-0">
          <div className="">&copy; {copyrightYear} by Grayscourt Limited</div>
          <div>
            Site by
            <a
              href="https://www.barques.co.uk/"
              rel="noopener nofollow"
              className="ml-1 font-bold hover:underline"
            >
              Barques
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
