import React from "react";

const Logo = ({ fill = "#0d1d31", className }) => {
  return (
    <svg
      viewBox="0 0 41.6 41.6"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.9 30.3L24.3 40.9c-.4.4-1 .4-1.3 0-.4-.4-.4-1 0-1.3L33.6 29c.4-.4 1-.4 1.3 0 .4.3.4.9 0 1.3.1 0 .1 0 0 0zm6.4.4L30.9 41c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5l10.3-10.3c.4-.4 1.1-.4 1.5 0 .5.4.5 1 .1 1.5zm0-6.6c-.4.4-1.1.4-1.5 0L28.6 13c-.4-.4-.4-1.1 0-1.5.4-.4 1.1-.4 1.5 0l11.2 11.2c.4.3.4 1 0 1.4zm0-6.5c-.4.4-1.1.4-1.5 0l-7.9-7.9c-.4-.4-.4-1.1 0-1.5.4-.4 1.1-.4 1.5 0l7.9 7.9c.4.4.4 1.1 0 1.5zM25.5 1.8L8.6 18.7c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5L24 .3c.4-.4 1.1-.4 1.5 0s.4 1.1 0 1.5zm-6.9.2L7.9 12.7c-.4.4-1 .4-1.3 0s-.4-1 0-1.3L17.3.7c.4-.4 1-.4 1.3 0 .4.3.4.9 0 1.3zm-6.4.1L1.8 12.4c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5L10.7.6c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5zM17.8 35c-.4.4-1 .4-1.4 0L.4 18.9c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l16.1 16.1c.3.3.3 1-.1 1.4 0-.1 0 0 0 0zm-.2 6.3c-.4.4-1.1.4-1.5 0L.3 25.5c-.4-.4-.4-1.1 0-1.5.4-.4 1.1-.4 1.5 0l15.8 15.8c.4.4.4 1.1 0 1.5zm16.9-16.9L17.6 41.3c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5L33 22.9c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5zm6.8 6.3c-.4.4-1.1.4-1.5 0L25.3 16.2c-.4-.4-.4-1.1 0-1.5.4-.4 1.1-.4 1.5 0l14.4 14.4c.5.5.5 1.1.1 1.6zm-17.2 4c-.4.4-1.1.4-1.5 0L.3 12.4c-.4-.4-.4-1.1 0-1.5.4-.4 1.1-.4 1.5 0l22.3 22.3c.4.4.4 1.1 0 1.5z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
