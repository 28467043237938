const setTheme = {
  default: "theme-cinnabar",
  cinnabar: "theme-cinnabar",
  bespoke: "theme-bespoke",
  stormdust: "theme-stormdust",
  group: "theme-group",
  commercial: "theme-commercial",
  bobalife: "theme-bobalife",
  white: "theme-white",
};

export default setTheme;
