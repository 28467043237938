import React from "react";
import LinkInternal from "components/LinkInternal";
import Logo from "images/LogoShape";
import setTheme from "../../lib/theme";

const ContactBar = ({
  theme = "default",
  copy,
  link,
  contactBannerMessage,
}) => {
  return (
    <div
      className={`relative overflow-hidden ${setTheme[theme]} bg-skin-fill text-skin-base py-12 px-6 xl:px-0`}
    >
      <div className="absolute top-0 -right-16 transform -translate-y-2/3 opacity-20 w-96 h-96">
        <Logo fill={`white`} />
      </div>
      <div className="max-w-3xl mx-auto flex flex-col items-center">
        {copy && (
          <div
            className="text-center text-2xl uppercase font-light tracking-wider"
            dangerouslySetInnerHTML={{ __html: copy }}
          ></div>
        )}
        {!copy && (
          <div
            className="text-center text-2xl uppercase font-light tracking-wider"
            dangerouslySetInnerHTML={{ __html: contactBannerMessage }}
          ></div>
        )}
        {link && (
          <div className="mt-6 text-center">
            <LinkInternal target={link} label="Get in touch" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactBar;
