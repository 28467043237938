import React from "react";
import { HiMenuAlt3, HiX } from "react-icons/hi";

export const Hamburger = ({ isOpen, theme, setIsOpen, className }) => {
  return (
    <button
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      className={`${className} lg:hidden ml-2 flex flex-col justify-center items-center`}
    >
      {isOpen ? (
        <HiX
          className={`h-5 w-5 ${
            theme === "commercial" ? "text-cinnabar" : "text-white"
          }`}
        />
      ) : (
        <HiMenuAlt3
          className={`h-5 w-5 ${
            theme === "commercial" ? "text-cinnabar" : "text-white"
          }`}
        />
      )}
    </button>
  );
};
